.modal {
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: calc(100vw - 1rem);
  max-height: calc(100vh - 2rem);
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;
}
.modal.fullscreen {
  width: 100%;
  height: calc(var(--vhvh) - var(--app-header));
  top: calc(var(--app-header) / 2) !important;
}
.modal.fullscreen .modal-wrapper {
  width: 100%;
  height: 100%;
}
.modal.fullscreen .modal-wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'header'
    'content'
    'footer';
}
.modal.fullscreen .modal-content {
  overflow: auto;
}

.modal .modal-header {
  grid-area: header;
  display: grid;
  grid-template-areas: 'title extra';
  grid-template-columns: 1fr auto;
  padding: 1rem;
  padding-bottom: 0;
  /* padding-bottom: 5px; */
}
.modal .modal-header-title {
  grid-area: title;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  flex: 1;
}
.modal .modal-header-extra {
  grid-area: extra;
  display: flex;
  gap: 0.4rem;
}

.modal .modal-header-extra .close-button {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding: 0;
  background-color: transparent !important;
  color: #545454 !important;
}
.modal .modal-header-extra .close-button:hover {
  background-image: none !important;
  background-color: transparent !important;
  color: #0c0c0c !important;
}

.modal .modal-header-extra .close-button > i {
  font-size: 20px;
}

.modal .modal-content {
  grid-area: content;
  padding: 0.4rem 1rem;
}
.modal .modal-content .row:last-of-type {
  margin-bottom: 0 !important;
}
.modal:has(:not(.modal-footer)) .modal-content {
  padding-bottom: 1rem;
}

.modal .modal-footer {
  grid-area: footer;
  border-radius: 0;
  background-color: #fff;
  padding: 1rem;
  padding-top: 0;
  height: 56px;
  width: 100%;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
}
.modal .modal-footer > * {
  flex: 1;
  margin: 0 !important;
}

/* swal2 */
.swal2-popup {
  display: none;
  position: relative;
  box-sizing: border-box;
  grid-template-columns: minmax(0, 100%);
  width: 32em;
  max-width: calc(100vw - 2rem);
  padding: 1rem;
  padding-bottom: 5px;
  border: none;
  border-radius: 5px;
  background: #fff;
  color: #545454;
  font-family: inherit;
  font-size: 1rem;

  /* max-height: calc(100vh - 2rem);
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: none !important; */
}
.swal2-popup:has(.swal2-actions) {
  padding-bottom: 1rem;
}

.swal2-styled.swal2-default-outline:focus {
  box-shadow: none !important;
}

.swal2-icon {
  margin: 0.6em auto 0.6em;
  top: 50%;
  transform: translate(0, -50%);
  animation: none !important;
}
.swal2-title {
  font-size: 1.4rem !important;
}
.swal2-html-container__list {
  text-align: left;
  margin: 0;
  font-size: 1rem;
}
.swal2-html-container__list__title {
  font-weight: 600;
  font-size: 1rem;
}
.swal2-html-container__list__list {
  list-style-type: circle !important;
  margin-bottom: 10px;
  padding-left: 30px !important;
  max-height: 200px;
  overflow: auto;
}
.swal2-html-container__list__list ul {
  padding-left: 10px !important;
}
.swal2-html-container__list ul:not(.browser-default),
.swal2-html-container__list ul:not(.browser-default) > li {
  list-style-type: inherit;
}

.ant-switch-disabled,
.ant-switch-loading,
.ant-switch-disabled *,
.ant-switch-loading * {
  cursor: default;
  opacity: 0.6;
}
.ant-btn[disabled],
.ant-checkbox-disabled .ant-checkbox-inner,
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  cursor: default;
}

.ant-input-disabled:hover,
.ant-input[disabled]:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: #d9d9d9;
}

/** IFRAME **/
.iframe-wrapper {
  background-color: rgb(50, 54, 57);
}
.iframe-wrapper iframe {
  height: 100%;
  width: 100%;
  border: none;
}
.iframe-wrapper .iframe-footer {
  padding: 0.6rem 0;
}
.iframe-wrapper .iframe-footer .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.iframe-wrapper .iframe-footer .pagination .page-item .page-link {
  background-color: transparent;
  border-color: transparent;
  color: rgb(241, 241, 241);
}
.iframe-wrapper .iframe-footer .pagination .page-item.active .page-link {
  background-color: rgb(241, 241, 241) !important;
  border-color: rgb(241, 241, 241) !important;
  color: rgb(50, 54, 57) !important;
}

/* END IFRAME */
