.dropdown-content li > a,
.dropdown-content li > span {
  color: var(--accent-bg-color);
}

.dropdown-content li > a > i {
  margin: 0;
  color: var(--secondary-text-color);
}

.dropdown-content {
  bottom: 0;
  top: unset !important;
  transform: scaleX(1) scaleY(1) translate(0, -48px) !important;
}
