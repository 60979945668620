.btn,
.btn-large,
.btn-small {
  background-color: var(--btn-bg-color) !important;
  color: var(--btn-font-color) !important;
  text-transform: none;
  box-shadow: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}
.a {
  color: var(--btn-bg-color) !important;
  opacity: 0.8;
}
.a:hover {
  opacity: 1;
}

.btn:focus,
.btn-large:focus,
.btn-small:focus,
.btn-floating:focus,
.btn:hover,
.btn-large:hover,
.btn-small:hover {
  background-color: var(--btn-bg-color) !important;
  color: var(--btn-font-color) !important;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
  box-shadow: none;
}
button:focus {
  background: none;
}

.btn .mdi {
  font-size: 16px;
}

.btn-service {
  text-transform: none !important;
}

.btn.btn-solo-icon {
  padding: 0;
  width: 36px;
  height: 36px;
}
.btn.btn-solo-icon.full-width {
  width: 100%;
}

.btn-primary {
  border: 1px solid var(--accent-bg-color);
}
.btn-outline {
  --btn-bg-color: #fff;
  --btn-font-color: var(--accent-bg-color);
  border: 1px solid var(--accent-bg-color);
}
.btn-outline:focus,
.btn-outline-large:focus,
.btn-outline-small:focus,
.btn-outline-floating:focus,
.btn-outline:hover,
.btn-outline-large:hover,
.btn-outline-small:hover {
  background-image: none;
}
.btn-text {
  --btn-bg-color: #fff;
  --btn-font-color: var(--accent-bg-color);
}
.btn-text:focus,
.btn-text-large:focus,
.btn-text-small:focus,
.btn-text-floating:focus,
.btn-text:hover,
.btn-text-large:hover,
.btn-text-small:hover {
  background-image: none;
}

.btn.red {
  --btn-bg-color: var(--red);
}

.btn-grey {
  --btn-bg-color: #757575 !important;
  --btn-font-color: #fff;
}
.btn-floating {
  --btn-bg-color: transparent !important;
  --btn-font-color: #fff;
}

.btn-success {
  --btn-bg-color: var(--green);
  --btn-font-color: #fff;
}
.btn-error {
  --btn-bg-color: var(--red);
  --btn-font-color: #fff;
}
.btn-success,
.btn-error {
  text-decoration: none;
  text-transform: none !important;
  white-space: nowrap;
}

.btn.btn-icon {
  --btn-bg-color: transparent;
  --btn-font-color: var(--accent-bg-color);
  border: none !important;
}
.btn.btn-icon:hover {
  --btn-font-color: var(--accent-bg-color--hover);
  background-image: none !important;
}

/** FLOATING **/
.menu-floating-button {
  position: absolute !important;
  bottom: 1rem;
  right: 1rem;
  background-color: var(--accent-bg-color) !important;
  --mdc-fab-icon-color: var(--accent-font-color) !important;
  --mat-mdc-fab-color: var(--accent-font-color) !important;
  font-size: 2rem;
}

.mat-mdc-menu-content a {
  color: var(--primary-text-color);
}
.mat-mdc-menu-content button:hover a {
  color: var(--accent-bg-color);
}
/* END FLOATING */
