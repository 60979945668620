.card {
  position: relative;
  margin: 0px 0 10px 0 !important;
  background-color: #fff;
  transition: box-shadow 0.25s;
  padding: 1rem;
}

.card.rounded {
  border-radius: 4px !important;
}
.card.rounded-lg {
  border-radius: 10px !important;
}

.card .card-header {
  display: grid;
  grid-template-areas:
    'before before'
    'title extra'
    'subtitle extra';
  grid-template-columns: 1fr auto;
  gap: 0.4rem;
}
.card:has(.card-content) .card-header {
  margin-bottom: 0.4rem;
}

.card .card-header .card-header-title {
  grid-area: title;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  flex: 1;
  overflow: hidden;
}
.card .card-header .card-header-title span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.card .card-header .card-header-subtitle {
  grid-area: subtitle;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  flex: 1;
}

.card .card-header .card-header-extra {
  grid-area: extra;
  display: flex;
  gap: 0.4rem;
}

.card .card-header .card-header-before {
  grid-area: before;
  display: flex;
  gap: 0.4rem;
}
.card .card-header .card-header-back {
  font-size: 1.1rem;
  color: #ccc;
  line-height: 1;
  font-weight: 700;
  text-decoration: underline;
}

.card .card-content {
  padding: 0 !important;
}
.card .card-content__filter {
  margin: 1rem 0 !important;
  border-bottom: 1px solid #e0e0e0;
}

.card .card-content .row:last-of-type {
  margin-bottom: 0 !important;
}

.card-content-code {
  width: 100%;
  padding: 1rem 0.4rem;
  background-color: #f5f5f5;
}

.card .card-footer {
  margin-top: 1rem;
}

.card .btn-wrapper {
  display: flex;
  gap: 0.4rem;
}

.card .btn-wrapper > * {
  flex: 1;
}

.card.inner-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0), 0 1px 1px -4px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.card.inner-card .card-content.row {
  margin-left: 0;
  margin-right: 0;
}
.card.inner-card .row .col {
  padding: 0;
}
.card.inner-card .no-results-wrapper {
  width: unset;
}
