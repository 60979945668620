.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.skeleton-title {
  width: 60%;
  height: 1.2rem;
  border-radius: 0.25rem;
}

.skeleton-subtitle {
  width: 40px;
  height: 1rem;
  border-radius: 0.25rem;
}

.skeleton-text {
  width: 100%;
  height: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 0.25rem;
}

.skeleton-text_body {
  width: 75%;
}

.skeleton-footer {
  width: 30%;
}
