@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '../assets/icons/_materialdesignicons.css';

@import './_variables.css';

@import './badge.css';
@import './button.css';
@import './card.css';
@import './dropdown.css';
@import './input.css';
@import './loading.css';
@import './modal.css';
@import './skeleton.css';
@import './table-card.css';
@import './table.css';
@import './text.css';
@import './xtra.css';

body {
  /* font-family: 'Roboto', sans-serif !important; */
  background-color: #f0f2f5;
  height: 0 !important;
}

button,
input,
optgroup,
select,
textarea {
  /* font-family: 'Roboto', sans-serif !important; */
}

::-webkit-scrollbar {
  width: var(--scroll-width);
  height: var(--scroll-width);
}
::-webkit-scrollbar-track {
  background: var(--sroll-track-color);
}
::-webkit-scrollbar-thumb {
  background: var(--sroll-thumb);
  border-radius: var(--scroll-border-radius);
}
::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-thumb-hover);
}
