:root {
  --app-header: 60px;
  --app-content-header: 60px;
  --app-user-header: 4rem;
  --app-footer: 4rem;

  --vhvh: calc(var(--vh, 1vh) * 100);

  /* Colors */
  --primary-color: #001529;
  --accent-bg-color: #1890ff;
  --accent-font-color: #fff;
  --accent-bg-color--hover: #0b81f1;

  --primary-text-color: #000;
  --secondary-text-color: #b8b8b8;

  --red: #f44336;
  --green: #1abc9c;
  --blue: #273ff3;

  --scroll-width: 4px;
  --scroll-border-radius: 1rem;
  --sroll-track-color: #d4d4d4;
  --sroll-thumb: #aaaaaa;
  --scroll-thumb-hover: #969696;

  --btn-bg-color: var(--accent-bg-color);
  --btn-font-color: var(--accent-font-color);
}
