.status-badge {
  font-size: 0.8rem;
  padding: 0 6px;
  height: 20px;
  line-height: normal;
  border-radius: 4px;
  width: fit-content;

  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}
