.loading > * > .backdrop {
  z-index: 1999;
  opacity: 19 !important;
  height: 100%;
  position: absolute;
  inset: 0;
  background-color: #0000007d !important;
  width: 100% !important;
  visibility: visible !important;
}

.loading .spinner-layer {
  border-color: var(--accent-bg-color);
}
