.card-table-wrapper {
}

.card-table-wrapper .card-table-header {
  display: grid;
  grid-template-areas: 'title extra';
  grid-template-columns: 1fr auto;
  margin-bottom: 1rem;
  min-height: 32px;
}
.card-table-wrapper .card-table-header-title {
  grid-area: title;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  flex: 1;
  gap: 0.4rem;
}
.card-table-wrapper .card-table-header-extra {
  grid-area: extra;
  display: flex;
  gap: 0.4rem;
}

.card-table-wrapper .card-table-header-extra .btn.btn-icon {
  height: 30px;
  width: 30px;
}
.card-table-wrapper .card-table-header-extra .btn.btn-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-table-wrapper .card-table-content .card .row {
  margin: 0;
  margin-bottom: 0.4rem;
}
.card-table-wrapper .card-table-content .card .row {
  margin: 0;
  margin-bottom: 0.4rem;
}

.card-table-wrapper .card-table-content .card .card-label {
  font-weight: 500;
}

.card-table-wrapper .no-data-table-wrapper {
  background-color: #fff !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-weight: 500;
  font-size: 14px;
  padding: 1rem;
}

.card-table-wrapper .card .card-footer {
  font-size: 0.8rem;
}

.card-table-wrapper .mat-card {
  margin-bottom: 0.4rem;
}
.card-table-wrapper .mat-mdc-card-header {
  padding: 0.8rem 0.8rem 0;
}
.card-table-wrapper .mat-mdc-card-content {
  padding: 0 0.8rem;
}
.card-table-wrapper .mat-mdc-card-actions {
  padding: 0 0.8rem;
  min-height: 30px;
}
.card-table-wrapper .mat-mdc-card-actions .btn.btn-solo-icon {
  padding: 0;
}
.status-badge-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
