.table-wrapper {
}

.table-wrapper .table-header {
  display: grid;
  grid-template-areas: 'title extra';
  grid-template-columns: 1fr auto;
  margin-bottom: 1rem;
  min-height: 32px;
}
.table-wrapper .table-header-title {
  grid-area: title;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
  flex: 1;
  gap: 0.4rem;
}
.table-wrapper .table-header-extra {
  grid-area: extra;
  display: flex;
  gap: 0.4rem;
}

.table-wrapper .subheader {
  font-weight: 600;
  /* text-align: center; */
  padding: 4px;
}

.table-wrapper .table-header-extra .btn.btn-icon {
  height: 30px;
  width: 30px;
}
.table-wrapper .table-header-extra .btn.btn-icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.table-wrapper .table-content {
  overflow: overlay;
}

.table-wrapper .table-content table {
  width: 100%;
  border-collapse: collapse;
}

.mat-mdc-table .mdc-data-table__header-row,
.table-wrapper .table-content table thead {
  background-color: #f2f2f2;
  text-align: left;
  height: 44px;
}

.table-wrapper .table-content table thead td {
  font-weight: 700;
}

.table-wrapper .table-content table td,
.table-wrapper .table-content table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.table-wrapper .table-content table th {
  background-color: #f9f9f9;
  font-weight: 600;
  font-size: 14px;
}
.table-wrapper .table-content table th.opt-col {
  text-align: center;
}

.table-wrapper .table-content table td input {
  width: 100%;
  box-sizing: border-box;
}

.table-wrapper .table-footer {
  margin-top: 0.4rem;
}
.table-wrapper .table-footer .table-footer-pagination {
}
.table-wrapper .table-footer .table-footer-legend {
  margin-top: 0.4rem;
  font-size: 0.8rem;
}

.table-error td,
.table-error th {
  padding: 5px 5px !important;
}

.table-wrapper .loading-wrapper .loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.table-wrapper .no-data {
  font-weight: 500;
  text-align: center;
  font-size: 15px;
}

.status-col {
  text-align: center;
  width: 120px;
}

.mat-column-options,
.options-col {
  text-align: center;
  width: 50px;
}
.options-col span {
  margin: 5px;
  cursor: pointer;
}
.options-col .btn {
  padding: 0;
}

.mat-column-options {
  width: 50px;
}
.mat-column-options.mdc-data-table__cell {
  text-align: center;
}

.no-data-table-wrapper {
  background-color: #f9f9f9 !important;
}
.no-data-table-wrapper td {
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}
.no-data-table-wrapper td i {
  margin-right: 0.4rem;
}
.no-results-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 4px;
  padding: 1rem 0.4rem;
  background-color: #f9f9f9 !important;
}
