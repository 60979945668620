.full-width {
  width: 100%;
}

.full-height {
  height: calc(var(--vhvh) - var(--app-header));
  display: grid;
}

.user-photo {
  object-fit: cover;
}

.custom-class {
  top: 0;
  z-index: 2002;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: -55px;
}

.custom-class p {
  color: white;
  font-size: 18px;
}

span.field-icon {
  float: right;
  position: absolute;
  right: 18px;
  top: 7px;
  font-size: 22px;
  color: #5a5a5a;
  cursor: pointer;
  z-index: 2;
}

.login-button {
  width: 100%;
  margin-bottom: 0.75em;
}

.card-link-wrapper {
  font-size: 16px;
  display: flex;
  gap: 0.4rem;
}

nav.nav-extended .nav-wrapper {
  min-height: var(--app-header) !important;
  height: var(--app-header) !important;
}

a {
  cursor: pointer;
}
a.disabled {
  opacity: 0.6;
}

.row {
  margin-bottom: 0.4rem;
}

.divider {
  margin: 0.8rem 0;
}

.hidden {
  display: none;
}

.logo {
  height: 35px;
  /* margin-top: 12px; */
}

.topbar {
  background-color: var(--primary-color);
  box-shadow: none;
}
