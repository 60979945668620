textarea + label,
.input-field input + label {
  pointer-events: none;
}
textarea + label *,
.input-field input + label * {
  pointer-events: all;
}

.input-field .prefix ~ input,
.input-field .prefix ~ textarea,
.input-field .prefix ~ label,
.input-field .prefix ~ .validate ~ label,
.input-field .prefix ~ .helper-text,
.input-field .prefix ~ .error-text,
.input-field .prefix ~ .autocomplete-content {
  margin-left: 3rem;
  width: 92%;
  width: calc(100% - 3rem);
}

.input-field .error-text {
  position: relative;
  min-height: 18px;
  display: block;
  font-size: 12px;
  color: #f44336;
}

.invalid input,
input.invalid {
  border-bottom: 1px solid #f44336 !important;
  box-shadow: none !important;
}
.input-field-2 .invalid,
.ng-invalid .invalid,
.ng-invalid .input-error {
  border: 1px solid #f44336 !important;
  box-shadow: none !important;
}

input:focus {
  border-bottom: 1px solid var(--accent-bg-color);
  box-shadow: none !important;
}

input.swal2-input {
  margin-top: 18px;
}

input:not([type]):disabled,
input:not([type])[readonly='readonly'],
input[type='text']:not(.browser-default):disabled,
input[type='text']:not(.browser-default)[readonly='readonly'],
input[type='password']:not(.browser-default):disabled,
input[type='password']:not(.browser-default)[readonly='readonly'],
input[type='email']:not(.browser-default):disabled,
input[type='email']:not(.browser-default)[readonly='readonly'],
input[type='url']:not(.browser-default):disabled,
input[type='url']:not(.browser-default)[readonly='readonly'],
input[type='time']:not(.browser-default):disabled,
input[type='time']:not(.browser-default)[readonly='readonly'],
input[type='date']:not(.browser-default):disabled,
input[type='date']:not(.browser-default)[readonly='readonly'],
input[type='datetime']:not(.browser-default):disabled,
input[type='datetime']:not(.browser-default)[readonly='readonly'],
input[type='datetime-local']:not(.browser-default):disabled,
input[type='datetime-local']:not(.browser-default)[readonly='readonly'],
input[type='tel']:not(.browser-default):disabled,
input[type='tel']:not(.browser-default)[readonly='readonly'],
input[type='number']:not(.browser-default):disabled,
input[type='number']:not(.browser-default)[readonly='readonly'],
input[type='search']:not(.browser-default):disabled,
input[type='search']:not(.browser-default)[readonly='readonly'],
.mat-mdc-select-disabled,
.mat-mdc-select-disabled .mat-mdc-select-value,
textarea.materialize-textarea:disabled,
textarea.materialize-textarea[readonly='readonly'] {
  background-color: #f5f5f5 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

input:not([type]):focus:not([readonly]) + label,
input[type='text']:not(.browser-default):focus:not([readonly]) + label,
input[type='password']:not(.browser-default):focus:not([readonly]) + label,
input[type='email']:not(.browser-default):focus:not([readonly]) + label,
input[type='url']:not(.browser-default):focus:not([readonly]) + label,
input[type='time']:not(.browser-default):focus:not([readonly]) + label,
input[type='date']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime']:not(.browser-default):focus:not([readonly]) + label,
input[type='datetime-local']:not(.browser-default):focus:not([readonly]) + label,
input[type='tel']:not(.browser-default):focus:not([readonly]) + label,
input[type='number']:not(.browser-default):focus:not([readonly]) + label,
input[type='search']:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: var(--accent-bg-color);
}

input:not([type]):focus.valid ~ label,
input[type='text']:not(.browser-default):focus.valid ~ label,
input[type='password']:not(.browser-default):focus.valid ~ label,
input[type='email']:not(.browser-default):focus.valid ~ label,
input[type='url']:not(.browser-default):focus.valid ~ label,
input[type='time']:not(.browser-default):focus.valid ~ label,
input[type='date']:not(.browser-default):focus.valid ~ label,
input[type='datetime']:not(.browser-default):focus.valid ~ label,
input[type='datetime-local']:not(.browser-default):focus.valid ~ label,
input[type='tel']:not(.browser-default):focus.valid ~ label,
input[type='number']:not(.browser-default):focus.valid ~ label,
input[type='search']:not(.browser-default):focus.valid ~ label,
textarea.materialize-textarea:focus.valid ~ label {
  color: var(--accent-bg-color);
}

input:not([type]):focus:not([readonly]),
input[type='text']:not(.browser-default):focus:not([readonly]),
input[type='password']:not(.browser-default):focus:not([readonly]),
input[type='email']:not(.browser-default):focus:not([readonly]),
input[type='url']:not(.browser-default):focus:not([readonly]),
input[type='time']:not(.browser-default):focus:not([readonly]),
input[type='date']:not(.browser-default):focus:not([readonly]),
input[type='datetime']:not(.browser-default):focus:not([readonly]),
input[type='datetime-local']:not(.browser-default):focus:not([readonly]),
input[type='tel']:not(.browser-default):focus:not([readonly]),
input[type='number']:not(.browser-default):focus:not([readonly]),
input[type='search']:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
  border-bottom: 1px solid var(--accent-bg-color);
  box-shadow: 0 1px 0 0 var(--accent-bg-color);
}

input.valid:not([type]),
input.valid:not([type]):focus,
input.valid[type='text']:not(.browser-default),
input.valid[type='text']:not(.browser-default):focus,
input.valid[type='password']:not(.browser-default),
input.valid[type='password']:not(.browser-default):focus,
input.valid[type='email']:not(.browser-default),
input.valid[type='email']:not(.browser-default):focus,
input.valid[type='url']:not(.browser-default),
input.valid[type='url']:not(.browser-default):focus,
input.valid[type='time']:not(.browser-default),
input.valid[type='time']:not(.browser-default):focus,
input.valid[type='date']:not(.browser-default),
input.valid[type='date']:not(.browser-default):focus,
input.valid[type='datetime']:not(.browser-default),
input.valid[type='datetime']:not(.browser-default):focus,
input.valid[type='datetime-local']:not(.browser-default),
input.valid[type='datetime-local']:not(.browser-default):focus,
input.valid[type='tel']:not(.browser-default),
input.valid[type='tel']:not(.browser-default):focus,
input.valid[type='number']:not(.browser-default),
input.valid[type='number']:not(.browser-default):focus,
input.valid[type='search']:not(.browser-default),
input.valid[type='search']:not(.browser-default):focus,
textarea.materialize-textarea.valid,
textarea.materialize-textarea.valid:focus,
.select-wrapper.valid > input.select-dropdown {
  border-bottom: 1px solid var(--accent-bg-color);
  box-shadow: 0 1px 0 0 var(--accent-bg-color);
}

.switch label input[type='checkbox']:checked + .lever {
  background-color: var(--accent-bg-color--hover);
}
.switch label input[type='checkbox']:checked + .lever:after {
  background-color: var(--accent-bg-color);
}
.switch input[type='checkbox'][disabled] + .lever {
  background-color: #bbbbbb !important;
}

.input-field-2[required] label,
.input-field[required] label {
  position: relative;
  display: flex;
}
.input-field-2[required] label::after,
.input-field[required] label::after {
  content: ' *';
  font-size: 0.6rem;
  color: #f44336;
}
.input-field-2[required]:not(:has(label)) {
  position: relative;
}
.input-field-2[required]:not(:has(label))::after {
  content: ' *';
  position: absolute;
  right: 6px;
  top: 2px;
  font-size: 0.6rem;
  color: #f44336;
}
.input-field-2[required] .invalid,
.input-field[required] {
  border-color: #f44336 !important;
}

/* SELECT */
select {
  border: none;
  border-bottom: 1px solid #ddd;
  border-radius: 0;
  margin: 0 0 8px 0;
  padding: 0;
  height: 43px;
}
select:focus {
  outline: none;
  border-bottom: 1px solid var(--accent-bg-color);
}
.select-wrapper input.select-dropdown:focus {
  border-color: var(--accent-bg-color);
}
.select-wrapper .dropdown-content li > a,
.select-wrapper .dropdown-content li > span {
  color: #000;
}
.select-wrapper .select-dropdown.dropdown-content li.selected > span {
  color: var(--accent-bg-color);
}
.select-dropdown li.disabled > span {
  color: #ddd !important;
}
.mat-mdc-select-disabled .mat-mdc-select-arrow-wrapper {
  display: none;
}

/* CHECKBOX */
[type='checkbox'].filled-in:checked + span:not(.lever):after {
  border-color: var(--accent-bg-color);
  background-color: var(--accent-bg-color);
}
[type='checkbox'].filled-in:disabled:not(:checked) + span:not(.lever):after {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}

/* DATEPICKER */
.dp-input-field {
  display: flex;
  flex-direction: column;
  position: relative;
}
.dp-input-field mat-form-field .mdc-text-field {
  padding: 0;
}
.mat-mdc-form-field-flex {
  height: 100%;
  justify-content: center;
  align-items: center;
}
.dp-input-field mat-datepicker-toggle {
  position: absolute;
  right: 0;
}
.dp-input-field mat-datepicker-toggle button {
  padding: 0 !important;
}

.dp-range-input-field {
  width: 100%;
  border: 1px solid #ddd !important;
}
.dp-range-input-field .mdc-text-field--filled:not(.mdc-text-field--disabled),
.dp-range-input-field .mat-mdc-form-field-focus-overlay {
  background-color: transparent;
}
.dp-range-input-field input {
  height: 100% !important;
}
.dp-range-input-field .mat-mdc-form-field-infix {
  min-height: unset;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.dp-range-input-field .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

button.mat-calendar-body-cell:focus {
  background-color: none !important;
}

.mat-date-range-input-wrapper {
  width: 100%;
  text-align: center;
}

.mdc-line-ripple {
  display: none;
}

/* END DATEPICKER */

/* .input-field-2 */

.dp-range-input-field,
.dp-input-field input,
.input-field-2 input,
.input-field-2 textarea {
  /* all: revert; */
  box-sizing: border-box !important;
  width: 100% !important;
  font-size: 14px !important;
  margin: 0 0 4px 0 !important;
  padding: 0 4px !important;
  background-color: transparent;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  outline: none !important;
  text-align: left;
}
.dp-input-field input,
.input-field-2 input {
  height: 38px !important;
}
.input-field-2 textarea {
  line-height: normal !important;
  overflow-y: hidden !important;
  padding: 4px !important;
  resize: none !important;
  min-height: 38px !important;
  box-sizing: border-box !important;
  min-height: 100px !important;
}
.input-field-2 input:focus,
.input-field-2 textarea:focus,
.input-field-2 input.valid:not([type]),
.input-field-2 input.valid:not([type]):focus,
.input-field-2 input.valid[type='text']:not(.browser-default),
.input-field-2 input.valid[type='text']:not(.browser-default):focus,
.input-field-2 input.valid[type='password']:not(.browser-default),
.input-field-2 input.valid[type='password']:not(.browser-default):focus,
.input-field-2 input.valid[type='email']:not(.browser-default),
.input-field-2 input.valid[type='email']:not(.browser-default):focus,
.input-field-2 input.valid[type='url']:not(.browser-default),
.input-field-2 input.valid[type='url']:not(.browser-default):focus,
.input-field-2 input.valid[type='time']:not(.browser-default),
.input-field-2 input.valid[type='time']:not(.browser-default):focus,
.input-field-2 input.valid[type='date']:not(.browser-default),
.input-field-2 input.valid[type='date']:not(.browser-default):focus,
.input-field-2 input.valid[type='datetime']:not(.browser-default),
.input-field-2 input.valid[type='datetime']:not(.browser-default):focus,
.input-field-2 input.valid[type='datetime-local']:not(.browser-default),
.input-field-2 input.valid[type='datetime-local']:not(.browser-default):focus,
.input-field-2 input.valid[type='tel']:not(.browser-default),
.input-field-2 input.valid[type='tel']:not(.browser-default):focus,
.input-field-2 input.valid[type='number']:not(.browser-default),
.input-field-2 input.valid[type='number']:not(.browser-default):focus,
.input-field-2 input.valid[type='search']:not(.browser-default),
.input-field-2 input.valid[type='search']:not(.browser-default):focus,
.input-field-2 textarea.materialize-textarea.valid,
.input-field-2 textarea.materialize-textarea.valid:focus,
.input-field-2 .select-wrapper.valid > input.select-dropdown {
  border: 1px solid var(--accent-bg-color) !important;
  box-shadow: none !important;
}

/* FORM */
.form-group-header {
  display: flex;
  align-items: center;
  margin-bottom: 0.4rem;
}
.form-group-title {
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: underline;
}
.form-group-extra {
  display: flex;
  margin-left: auto;
  gap: 0.4rem;
}
.form-group-extra .switch label .lever {
  margin: 0;
}

/* SELECT */

.input-field-2 select {
  height: 38px !important;
  font-size: 14px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  margin: 0 0 6px 0 !important;
  padding: 0 !important;
}
.input-field-2 select:focus {
  outline: none;
  border: 1px solid var(--accent-bg-color);
}
.input-field-2 .select-wrapper input.select-dropdown:focus {
  border-color: var(--accent-bg-color);
}
.input-field-2 .select-wrapper .dropdown-content li > a,
.input-field-2 .select-wrapper .dropdown-content li > span {
  color: #000;
}
.input-field-2 .select-wrapper .select-dropdown.dropdown-content li.selected > span {
  color: var(--accent-bg-color);
}
.input-field-2 .select-dropdown li.disabled > span {
  color: #9e9e9e !important;
}

.input-field-2 mat-select {
  height: 38px !important;
  font-size: 14px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  margin: 0 0 6px 0 !important;
  padding: 0 !important;
}
.input-field-2 mat-select:focus {
  outline: none;
  border: 1px solid var(--accent-bg-color);
}
.input-field-2 mat-select .mat-mdc-select-trigger {
  height: 100%;
  padding: 0 6px;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: transparent !important;
}

/* END SELECT */

/* FILE INPUT */
.file-input-group {
  display: flex;
  height: 38px !important;
  margin-bottom: 0.4rem;
}
.file-input-group input {
  height: 100% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  background-color: transparent !important;
  color: var(--accent-bg-color);
  cursor: default;
  margin: 0 !important;
}
.file-input-group .btn {
  height: 100% !important;
  border-radius: 0 !important;
}
.file-input-group .btn:last-of-type {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.file-input-list {
}
.file-input-list__header {
  display: flex;
}
.file-input-list__header__title {
  font-weight: 500;
  text-align: left;
  font-size: 1rem;
  color: #545454;
  flex: 1;
}
.file-input-list__header__actions {
}
.file-input-list__list {
  background-color: #f5f5f5;
}
.file-input-list__list__item {
  height: 40px;
  display: flex;
  padding: 0.6rem;
}
.file-input-list__list__item__name {
  text-align: left;
  flex: 1;
}
.file-input-list__list__item__name.no-data {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-weight: 500;
}
.file-input-list__list__item__actions {
}

.file-input-list__footer {
  display: flex;
  justify-content: center;
}
.file-input-list__footer button {
  flex: 1;
}

/* END FILE INPUT */

/* INPUT BTN GROUP */
.input-btn-group {
  position: relative;
}
.input-btn-group input {
  padding-right: 2rem !important;
}
.input-btn-group .search-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
  padding: 0 0.6rem;
  cursor: pointer;
}
.input-btn-group:has(label) .search-icon {
  top: calc(50% + 10px);
}
.input-btn-group .search-icon:hover {
  color: var(--accent-bg-color);
}
/* END FILE INPUT */

/* CHECKBOX */

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected ~ .mdc-checkbox__ripple {
  background: var(--accent-bg-color);
}
.mat-mdc-checkbox.mat-accent {
  --mdc-checkbox-selected-focus-icon-color: var(--accent-bg-color);
  --mdc-checkbox-selected-hover-icon-color: var(--accent-bg-color);
  --mdc-checkbox-selected-icon-color: var(--accent-bg-color);
  --mdc-checkbox-selected-pressed-icon-color: var(--accent-bg-color);
  --mdc-checkbox-selected-checkmark-color: var(--accent-font-color);
}

/* END CHECKBOX */
